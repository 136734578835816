import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatatableComponent } from 'src/app/members/shared/datatable/datatable.component';
import { AngularFireModule } from '@angular/fire';

import { environment } from 'src/environments/environment';
import { FirebaseStorageService } from 'src/app/services/firebaseStorage.service';
import { FileService } from 'src/app/services/file.service';
import { IonicModule } from '@ionic/angular';
import { AppMaterialModule } from 'src/app/app-material/app-material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HeaderComponent } from 'src/app/members/shared/header/header.component';
import { DialogComponent } from 'src/app/members/shared/dialog/dialog.component';
import { CancelaceptdialogComponent } from 'src/app/members/dashboard/shared/cancelaceptdialog/cancelaceptdialog.component';

@NgModule({
  declarations: [DatatableComponent, HeaderComponent, DialogComponent, CancelaceptdialogComponent],
  exports: [DatatableComponent, AppMaterialModule, FlexLayoutModule, HeaderComponent, DialogComponent],
  entryComponents: [DatatableComponent],
  imports: [
    IonicModule, AppMaterialModule,
    CommonModule,
    AngularFireModule.initializeApp(environment.firebaseConfig)
  ], providers: [FirebaseStorageService, FileService]
})
export class CoreModule {
  static COMPONENT_FACTORY = [
    DatatableComponent
  ];

}
