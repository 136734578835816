// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyCiomSAAY69uS2TRrJj4h5GGEjF8WVnzBQ",
    authDomain: "plataforma-borbalino.firebaseapp.com",
    databaseURL: "https://plataforma-borbalino.firebaseio.com",
    projectId: "plataforma-borbalino",
    storageBucket: "plataforma-borbalino.appspot.com",
    messagingSenderId: "188263426339",
    appId: "1:188263426339:web:9017e3cefcb974266ac496",
    measurementId: "G-XD846XXZDD"
  },
  appFolderPath: {
    rootPath: '/app-platform',
    admin: '/admin-af452spñz8887srp',
    app: '/app',
    students: '/students',
    teacher: '/teacher',
    classrooms: '/classrooms',
    notebooks: '/notebooks',
    practices: '/practices',
    subjects: '/subjects'
  },
  gamesSetting: {
    url: 'http://localhost:8102/main-games'
  },
  avatarFolderPath: '/assets/avatars',
  default_file_image: '/assets/img/007-files.svg',
  default_card_image: '/assets/img/notebook.svg',
  default_video_image: '/assets/img/002-video-file.svg',
  langFlagPath: "/assets/lang",
  max_files: 25,
  max_file_size: 5
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
