import { Injectable } from '@angular/core';

import { ToastController, } from '@ionic/angular';
@Injectable()
export class ToastService {
    toast: any = null;

    constructor(private toastCtrl: ToastController) { }

    async show(message:string){
        const toast = await this.toastCtrl.create({
          message: message,
          duration: 3000
        });
       toast.present();
      }

}