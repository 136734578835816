import { Injectable } from "@angular/core";

import { Observable } from 'rxjs';
import { PlatformFile } from '../models/file.model';
import { ApiService } from './api.service';


@Injectable()
export class FileService {

    static END_POINT = '/files';
    static END_POINT_USER_FILES_ALL = '/user/all';
    static END_POINT_GET = '/get';

    constructor(private _apiService: ApiService) {

    }

    readUserFiles(page: any, size: any): Observable<any> {
        return this._apiService.authToken().get(FileService.END_POINT
            + FileService.END_POINT_USER_FILES_ALL + FileService.END_POINT_GET + "?page=" + page + "&size=" + size);
    }

    readFile(fileId: string): Observable<PlatformFile> {
        return this._apiService.authToken().get(FileService.END_POINT
            + FileService.END_POINT_GET + "?id=" + fileId);
    }

    createFile(file: PlatformFile): Observable<PlatformFile> {
        return this._apiService.authToken().post(FileService.END_POINT
            , file);
    }

    updateFile(file: PlatformFile) {
        return this._apiService.authToken().put(FileService.END_POINT
            , file);
    }

    delete(fileId: string) {
        return this._apiService.authToken().delete(FileService.END_POINT
            + "?fileId=" + fileId);
    }

}