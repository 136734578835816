import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { retry, catchError, map } from 'rxjs/operators';
import { Role } from '../models/role.model';
import { Token } from '../models/token.model';
import { CookieService } from 'angular2-cookie/services/cookies.service';
import { MenuController } from '@ionic/angular';
import { ToastService } from './toas.service';

@Injectable()
export class ApiService {
    //static API_END_POINT = 'https://borbalino.com/api/';
    //static API_END_POINT = 'http://localhost:8080/api/v0';
   static API_END_POINT = 'https://platform.misrecursos.es/api/v0';

    static UNAUTHORIZED = 401;
    private params: URLSearchParams;
    //private UserAccessData = new UserAccessData();
    private headers: HttpHeaders;
    private token: Token;
    private email: string;
    //private responseType: ResponseContentType;

    constructor(private http: HttpClient, private router: Router, private _cookieService: CookieService, private toasService: ToastService, private _menu: MenuController) {
        this.resetOptions();
        this.token = undefined;
    }

    private resetOptions(): void {
        this.headers = new HttpHeaders();
        this.params = new URLSearchParams();
        //this.responseType = ResponseContentType.Text;
    }

    header(key: string, value: string): ApiService {
        this.headers.append(key, value);
        return this;
    }

    getRoles(): Array<Role> {
        if (this.token !== undefined) {
            return this.token.roles;
        } else {
            return undefined;
        }
    }

    login(email: string, password: string, remember: boolean, endPoint: string): Observable<any> {
        return this.authBasic(email, password).post(endPoint).pipe(map(
            token => {
                this.token = token;
                this.email = email;
                if (remember) {
                    this._cookieService.put('tokenid', this.token.token, { path: '/' });
                    this._cookieService.put('email', this.email, { path: '/' });
                    this._cookieService.put('rememberme', "true", { path: '/' });
                } else {
                    this._cookieService.put('tokenid', this.token.token, { expires: this.timeSessionCookie(), path: '/' });
                    this._cookieService.put('email', "false", { expires: this.timeSessionCookie(), path: '/' });
                    this._cookieService.put('rememberme', "false", { expires: this.timeSessionCookie(), path: '/' });
                }
            },
            error => this.logout()
        ));
    }


    timeSessionCookie(): Date {
        let curentTime = new Date();
        let expireTime = curentTime.getTime() + 3600000;
        curentTime.setTime(expireTime);
        return curentTime;
    }

    getTempToken(): Observable<Token> {

        return Observable.create(
            observer => {
                let token: Token = {
                    token: '',
                    roles: undefined
                }
                let tokenValue;
                let cookieToken = this._cookieService.get('tokenid');

                if (cookieToken) {
                    tokenValue = cookieToken;
                }
                if (tokenValue !== undefined) {
                    token.token = tokenValue;
                    token.roles = this.getRoles();
                    observer.next(token);
                    observer.complete();

                } else {
                    observer.next(null);
                    observer.complete();

                }
            }
        );
    }

    isAuth(): Observable<boolean> {
        return Observable.create(
            observer => {
                this.getTempToken().subscribe(data => {
                    if (data != null) {
                        observer.next(true);
                        observer.complete();
                    } else {
                        observer.next(false);
                        observer.complete();
                        this.logout();
                    }
                });
            }
        );
    }

    get(endpoint: string): Observable<any> {
        return this.http.get(ApiService.API_END_POINT + endpoint, this.createOptions()).pipe(retry(3),
            catchError(err => { return this.handleError(err) }));
    }

    post(endpoint: string, body?: Object): Observable<any> {
        return this.http.post(ApiService.API_END_POINT + endpoint, body, this.createOptions()).pipe(retry(3),
            catchError(err => { return this.handleError(err) }));
    }

    put(endpoint: string, body?: Object): Observable<any> {
        return this.http.put(ApiService.API_END_POINT + endpoint, body, this.createOptions()).pipe(retry(3),
            catchError(err => { return this.handleError(err) }));
    }

    delete(endpoint: string): Observable<any> {
        return this.http.delete(ApiService.API_END_POINT + endpoint, this.createOptions()).pipe(retry(3),
            catchError(err => { return this.handleError(err) }));
    }

    handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            if (error.status === ApiService.UNAUTHORIZED) {
                this.logout();
            }
            console.log(error.status);

            this.toasService.show('An error occurred:' + error.error.message);
            //this.message(error.error.message);
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);

        } else {
            if (error.status === ApiService.UNAUTHORIZED) {
                this.logout();
            }
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            this.toasService.show(`Backend returned code ${error.status}, ` +
                `body was: ${error.error.message}`);
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        return throwError(
            'Something bad happened; please try again later.');
    }

    authToken(): ApiService {
        let tokenValue = this._cookieService.get("tokenid");
        //alert(this._cookieService.get("tokenid"));
        if (tokenValue !== undefined) {
            // tokenValue = this.token.token;
            if (this._cookieService.get("rememberme") === "false") {
                if (this._cookieService.get("tokenid") && this._cookieService.get("email")) {
                    this._cookieService.remove('tokenid');
                    this._cookieService.remove('email');
                }
                this._cookieService.put('tokenid', tokenValue, { expires: this.timeSessionCookie(), path: '/' });
                this._cookieService.put('email', this.email, { expires: this.timeSessionCookie(), path: '/' });
            }
            this.headers = new HttpHeaders().set('Authorization', 'Basic ' + btoa(tokenValue + ':' + ''));
        } else {
            this.logout();
        }

        return this;
    }

    authBasic(email: string, password: string): ApiService {
        this.headers = new HttpHeaders().set('Authorization', 'Basic ' + btoa(email + ':' + password));
        return this;
    }

    private createOptions(): any {
        return {
            headers: this.headers
        }
    }

    logout(): void {
        this._menu.enable(false, 'main');
        this.token = undefined;
        this.resetAuthTokenCookie();
        this.router.navigate(['']);
    }

    resetAuthTokenCookie(): void {
        this._cookieService.remove('tokenid', { path: '/' });
        this._cookieService.remove('email', { path: '/' });
        this._cookieService.remove('rememberme', { path: '/' });
        this._cookieService.remove('userName', { path: '/' });
        this._cookieService.remove('teacherId', { path: '/' });
        this._cookieService.remove('userId', { path: '/' });
    }
}