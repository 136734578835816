import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-cancelaceptdialog',
  templateUrl: './cancelaceptdialog.component.html',
  styleUrls: ['./cancelaceptdialog.component.scss'],
})
export class CancelaceptdialogComponent implements OnInit {

  title: string;
  description: string;

  constructor(private _dialogRef: MatDialogRef<CancelaceptdialogComponent>) { }

  ngOnInit() { }

  close(res): void {
    this._dialogRef.close(res);
  }


}
