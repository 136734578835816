import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { ToastController } from '@ionic/angular';
import { Observable } from 'rxjs';

import { finalize } from 'rxjs/operators';
import { UploadFileData } from '../models/uploadFileData.model';

@Injectable()
export class FirebaseStorageService {


    filePath = 'uploads';
    constructor(private afStorage: AngularFireStorage, public toastCtrl: ToastController) {

    }

    uploadFileFromDevice(file: Blob, name: string, path: string) {
        let id = (Math.random().toString(36).substring(2) + 'x550s') + '_';

        const filePath = this.filePath + path;
        const fileRef = this.afStorage.ref(filePath).child(`${id + name}`);

        const task = fileRef.put(file);
        let uploadFileData: UploadFileData = {
            downloadurl: '',
            progress: undefined
        }
        return Observable.create(
            observer => {
                task.snapshotChanges().pipe(
                    finalize(() => {
                        fileRef.getDownloadURL().subscribe(
                            data => {
                                uploadFileData.downloadurl = data;
                                observer.next(uploadFileData);
                                observer.complete();
                                this.mensajes('Subida completa');
                            },
                            error => {
                                this.mensajes(error.message);
                            }
                        );

                    })
                ).subscribe()
            }
        );
    }

    uploadFile(file: File, path: string): Observable<UploadFileData> {
        let id = (Math.random().toString(36).substring(2) + 'x550s') + '_';

        const filePath = this.filePath + path;
        const fileRef = this.afStorage.ref(filePath).child(`${id + file.name}`);

        const task = fileRef.put(file);
        let uploadFileData: UploadFileData = {
            downloadurl: '',
            progress: undefined
        }
        return Observable.create(
            observer => {
                task.snapshotChanges().pipe(
                    finalize(() => {
                        fileRef.getDownloadURL().subscribe(
                            data => {
                                uploadFileData.downloadurl = data;
                                observer.next(uploadFileData);
                                observer.complete();
                                this.mensajes('Subida completa');
                            },
                            error => {
                                this.mensajes(error.message);
                            }
                        );

                    })
                ).subscribe()
            }
        );

    }

    deleteFile(file: any): Observable<boolean> {

        return Observable.create(
            observer => {
                this.afStorage.storage.refFromURL(file).delete().then(data => {
                    observer.next(data);
                    observer.complete();
                },
                    error => {
                        observer.next(error);
                        observer.complete();
                    });
            }
        );


    }

    mensajes(mesage: string): any {
        this.toastCtrl.create({
            message: mesage,
            duration: 2000
        });
    }
}