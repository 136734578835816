import { Component, ComponentFactoryResolver, ComponentRef, Inject, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit {

  componentRef: ComponentRef<any>;
  isEdit: boolean = false;

  @ViewChild('target', { read: ViewContainerRef, static: true }) vcRef: ViewContainerRef;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogComponent>,
    private resolver: ComponentFactoryResolver) {
  }

  ngOnInit() {
    const factory = this.resolver.resolveComponentFactory(this.data.component);
    this.componentRef = this.vcRef.createComponent(factory);
    this.componentRef.instance.data = this.data.dataInject;
    this.isEdit = this.data.dataInject.isEdit;
    this.componentRef.changeDetectorRef.detectChanges();
  }

  save(): void {
    this.componentRef.instance.save().subscribe(
      data => {
        this.dialogRef.close(data);
      }
    );
  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }
}
