import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, EventEmitter, Input, Output, ViewChild, Component, OnInit } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.scss']
})
export class DatatableComponent implements OnInit, AfterViewInit {

  displayedColumns: any[] = [];

  @Input()
  columns: any[];

  @Input()
  dataSource: MatTableDataSource<any>;

  @Input()
  currentLang: string;

  @Input()
  templateFavourites: any[];

  @Input()
  length = 500;

  @Input()
  pageSize = 10;

  @Input()
  pageIndex = 0;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Output()
  emittEditElement = new EventEmitter<any>();

  @Output()
  emittDeleteElement = new EventEmitter<any>();

  @Output()
  emittReadElement = new EventEmitter<any>();

  @Output()
  emittCreateElement = new EventEmitter<any>();

  @Output()
  emittTemplatesConfig = new EventEmitter<any>();

  @Input()
  isCrud: boolean = false;

  @Input()
  isConfig: boolean = false;

  @Input()
  isSelection: boolean = false;

  @Input()
  activePagination: boolean = false;

  @Output()
  emmitPaginator = new EventEmitter<any>();

  selection = new SelectionModel<any>(true, []);

  ngAfterViewInit(): void {
     this.dataSource.paginator = this.paginator;
  }

  ngOnInit() {
    this.displayedColumns = this.columns.map((column: any) => column.colname);
    if (this.isSelection) {
      let displayedColumns = ['select'].concat(this.displayedColumns)
      this.displayedColumns = displayedColumns;
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  onUpdate(element: any): void {
    this.emittEditElement.emit(element);
  }

  onDelete(element: any): void {
    this.emittDeleteElement.emit(element);
  }

  onRead(element: any): void {
    this.emittReadElement.emit(element);
  }

  onCreate() {
    this.emittCreateElement.emit();
  }

  onSetTemplatesConfig(option, templateFavourite) {
    let templatesConfig = {
      template: option.value,
      reportName: templateFavourite.reportName,
      templateFavourite: templateFavourite
    }
    this.emittTemplatesConfig.emit(templatesConfig);
  }

  handlePageEvent(event: PageEvent) {
    this.length = event.length;
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.emmitPaginator.emit(event);
  }

  masterToggleEvent(event) {
    if (event) {
      this.masterToggle();
    }
  }

  itemCheckEvent(event, row) {
    if (event) {
      this.selection.toggle(row);
    }
  }
}
