import { Component, Input, OnInit } from '@angular/core';
import { CookieService } from 'angular2-cookie/services/cookies.service';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  @Input()
  title: string = '';

  username: string = '';

  user: any;

  constructor(private _apiService: ApiService, private _cookieService: CookieService) { }

  ngOnInit() {
    this.user = JSON.parse(sessionStorage.getItem('user'));
    if (this.user && this.user['username'] === '6') {
      this.username = "J.R.FEIJOO";
    }
  }

  logOut() {
    this._apiService.logout();
  }

}
